package com.tanelso2.glmatrix

import org.khronos.webgl.Float32Array
import org.khronos.webgl.get

typealias Vec4JS = Float32Array

class Vec4(val array: Vec4JS) {
    constructor(x: Number, y: Number, z: Number, w: Number):
            this(vec4.fromValues(x.toFloat(), y.toFloat(), z.toFloat(), w.toFloat()))
    constructor(other: Vec4):
            this(vec4.clone(other.array))
    constructor(other: Vec3, w: Number = 1):
            this(other[0], other[1], other[2], w)
    constructor():
            this(vec4.create())

    fun dehomogenize(): Vec3 {
        val scalar = 1 / this[3]
        return Vec3(this * scalar)
    }

    operator fun plus(other: Vec4) = add(other)
    operator fun times(other: Vec4) = dot(other)
    operator fun times(scalar: Number) = scale(scalar)
    operator fun get(i: Int): Float {
        return array[i]
    }

    fun add(other: Vec4): Vec4 {
        val ret = Vec4()
        vec4.add(ret.array, this.array, other.array)
        return ret
    }

    fun multiply(other: Vec4): Vec4 {
        val ret = Vec4()
        vec4.multiply(ret.array, this.array, other.array)
        return ret
    }

    fun divide(other: Vec4): Vec4 {
        val ret = Vec4()
        vec4.divide(ret.array, this.array, other.array)
        return ret
    }

    fun transformMat4(m: Mat4): Vec4 {
        val ret = Vec4()
        vec4.transformMat4(ret.array, this.array, m.array)
        return ret
    }

    fun dot(other: Vec4): Number {
        return vec4.dot(this.array, other.array)
    }

    fun scale(scalar: Number): Vec4 {
        val ret = Vec4()
        vec4.scale(ret.array, this.array, scalar)
        return ret
    }
}

external open class vec4 {
    companion object {
        fun create(): Vec4JS = definedExternally
        fun clone(a: Vec4JS): Vec4JS = definedExternally
        fun fromValues(x: Number, y: Number, z: Number, w: Number): Vec4JS = definedExternally
        fun copy(out: Vec4JS, a: Vec4JS): Vec4JS = definedExternally
        fun set(out: Vec4JS, x: Number, y: Number, z: Number, w: Number): Vec4JS = definedExternally
        fun add(out: Vec4JS, a: Vec4JS, b: Vec4JS): Vec4JS = definedExternally
        fun subtract(out: Vec4JS, a: Vec4JS, b: Vec4JS): Vec4JS = definedExternally
        fun sub(): Nothing = definedExternally
        fun multiply(out: Vec4JS, a: Vec4JS, b: Vec4JS): Vec4JS = definedExternally
        fun mul(): Nothing = definedExternally
        fun divide(out: Vec4JS, a: Vec4JS, b: Vec4JS): Vec4JS = definedExternally
        fun div(): Nothing = definedExternally
        fun ceil(out: Vec4JS, a: Vec4JS): Vec4JS = definedExternally
        fun floor(out: Vec4JS, a: Vec4JS): Vec4JS = definedExternally
        fun min(out: Vec4JS, a: Vec4JS, b: Vec4JS): Vec4JS = definedExternally
        fun max(out: Vec4JS, a: Vec4JS, b: Vec4JS): Vec4JS = definedExternally
        fun round(out: Vec4JS, a: Vec4JS): Vec4JS = definedExternally
        fun scale(out: Vec4JS, a: Vec4JS, b: Number): Vec4JS = definedExternally
        fun scaleAndAdd(out: Vec4JS, a: Vec4JS, b: Vec4JS, scale: Number): Vec4JS = definedExternally
        fun distance(a: Vec4JS, b: Vec4JS): Number = definedExternally
        fun dist(): Nothing = definedExternally
        fun squaredDistance(a: Vec4JS, b: Vec4JS): Number = definedExternally
        fun sqrDist(): Nothing = definedExternally
        fun length(a: Vec4JS): Number = definedExternally
        fun len(): Nothing = definedExternally
        fun squaredLength(a: Vec4JS): Number = definedExternally
        fun sqrLen(): Nothing = definedExternally
        fun negate(out: Vec4JS, a: Vec4JS): Vec4JS = definedExternally
        fun inverse(out: Vec4JS, a: Vec4JS): Vec4JS = definedExternally
        fun normalize(out: Vec4JS, a: Vec4JS): Vec4JS = definedExternally
        fun dot(a: Vec4JS, b: Vec4JS): Number = definedExternally
        fun lerp(out: Vec4JS, a: Vec4JS, b: Vec4JS, t: Number): Vec4JS = definedExternally
        fun random(out: Vec4JS, scale: Number): Vec4JS = definedExternally
        fun transformMat4(out: Vec4JS, a: Vec4JS, m: Mat4JS): Vec4JS = definedExternally
        fun transformQuat(out: Vec4JS, a: Vec4JS, q: QuatJS): Vec4JS = definedExternally
        fun forEach(a: dynamic, stride: Number, offset: Number, count: Number, fn: dynamic, arg: dynamic): dynamic = definedExternally
        fun str(a: Vec4JS): String = definedExternally
        fun exactEquals(a: Vec4JS, b: Vec4JS): Boolean = definedExternally
        fun equals(a: Vec4JS, b: Vec4JS): Boolean = definedExternally
    }
}
