
import kotlinx.browser.document
import kotlinx.html.dom.append
import kotlinx.html.js.option
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.HTMLTextAreaElement
import org.w3c.dom.events.Event
import org.w3c.dom.get

class SourceSelector(
    private val resources: Resources,
    private val idPrefix: String,
    private val resourcePrefix: String,
    private val defaultResource: String,
    val updateFunction: (String) -> Unit,
) {
    private val selectorId: String = "$idPrefix-select"
    private val submitId: String = "$idPrefix-submit"
    private val sourceBoxId: String = "$idPrefix-source"

    fun init() {
        initForm()
    }

    private fun initForm() {
        // Add selectors for each file
        val select = document.getElementById(selectorId)!! as HTMLSelectElement
        select.append {
            for (source in resources.resourceURIs) {
                if (source.startsWith(resourcePrefix))
                    option {
                        +source
                        if (source.contains(defaultResource)) {
                            selected = true
                        }
                    }
            }
        }

        initEventListeners()

        // Set source box
        setSourceBox()

        // Submit
        submitSource()
    }

    private fun initEventListeners() {
        (document.getElementById(selectorId)!! as HTMLSelectElement).apply {
            addEventListener("input", ::selectListener)
        }

        (document.getElementById(submitId)!! as HTMLInputElement).apply {
            addEventListener("click", ::submitListener)
        }
    }

    @Suppress("UNUSED_PARAMETER")
    private fun selectListener(event: Event) {
        setSourceBox()
        submitSource()
    }

    @Suppress("UNUSED_PARAMETER")
    private fun submitListener(event: Event) {
        submitSource()
    }

    private fun setSourceBox() {
        val select = document.getElementById(selectorId)!! as HTMLSelectElement
        val sourceBox = document.getElementById(sourceBoxId)!! as HTMLTextAreaElement

        val selectedSource = select.selectedOptions.get(0)!!.textContent!!

        sourceBox.value = resources[selectedSource]!!
    }

    private fun submitSource(){
        console.info("Submitting another source")

        val sourceBox = document.getElementById(sourceBoxId)!! as HTMLTextAreaElement

        updateFunction(sourceBox.value!!)
    }

}