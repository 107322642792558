
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.HTMLCanvasElement
import org.khronos.webgl.WebGLRenderingContext as GL

fun main() {
    window.onload = {
        hideJavascriptError()
        WebGLWrapper()
    }
}

class WebGLWrapper{
    private val canvas: HTMLCanvasElement = document.getElementById("glCanvas") as HTMLCanvasElement
    private val gl: GL = canvas.getContext("webgl") as GL

    // resources
    val resourceList = arrayOf(
        "shaders/lighting.frag",
        "shaders/lighting.vert",

        "skeletons/empty.skel",
        "skeletons/dragon.skel",
        "skeletons/test.skel",
        "skeletons/tube.skel",
        "skeletons/wasp.skel",

        "skins/empty.skin",
        "skins/tube.skin",
        "skins/wasp.skin",

        "animations/empty.anim",
        "animations/wasp_walk.anim",
    )
    val resources = Resources(*resourceList, callAfter = ::runApplication)

    private val application = Application(gl, resources)

    /**
     * Don't wait for this thing?
     */
    init {
        // Setup
        setUpWebGLSettings()

        // Rest in runApplication()
    }

    /**
     * Called after resources are loaded. Mostly stuff in main() in the starte code.
     */
    private fun runApplication() {
        // Initialize
        application.initializePrograms()
        application.initializeObjects()

        // Loop
        window.requestAnimationFrame { loop(it) }

        // Cleanup? How?
    }

    /**
     * Copied from starter code
     */
    private fun setUpWebGLSettings() {
        gl.enable(GL.DEPTH_TEST)
        gl.depthFunc(GL.LEQUAL)
        gl.clearDepth(1f)

        gl.clearColor(0f, 0f, 0f, 1f)

        // Added to make the canvas clear.
        gl.clear(GL.COLOR_BUFFER_BIT)
    }

    /**
     * The animation loop.
     */
    private fun loop(currentTime: Double) {
        // Call stuff
        application.update(currentTime)

        application.draw()

        // Next frame
        window.requestAnimationFrame { loop(it) }
    }
}

/**
 * Removes the javascript error message by hiding it.
 */
private fun hideJavascriptError() {
    document.getElementById("javascript-error")?.classList?.add("hidden")
}
