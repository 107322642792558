
import com.tanelso2.glmatrix.Vec4
import org.khronos.webgl.WebGLRenderingContext as GL

class Material(
    val gl: GL,
    val ambient: Vec4 = Vec4(0.02, 0.05, 0.1, 1.0),
    val diffuse: Vec4 = Vec4(0.6, 0.65, 0.7, 1.0),
    val specular: Vec4 = Vec4(0.9, 0.9, 0.9, 1.0),
    val emission: Vec4 = Vec4(0.0, 0.0, 0.0, 1.0),
    val shininess: Float = 150f,
) {

    fun setUniforms(shaderProgram: ShaderProgram) {
        // Get uniform locations
        val ambientLoc = gl.getUniformLocation(shaderProgram.program, "ambient")
        val diffuseLoc = gl.getUniformLocation(shaderProgram.program, "diffuse")
        val specularLoc = gl.getUniformLocation(shaderProgram.program, "specular")
        val emisionLoc = gl.getUniformLocation(shaderProgram.program, "emission")
        val shininessLoc = gl.getUniformLocation(shaderProgram.program, "shininess")

        // Set uniforms
        gl.uniform4fv(ambientLoc, ambient.array)
        gl.uniform4fv(diffuseLoc, diffuse.array)
        gl.uniform4fv(specularLoc, specular.array)
        gl.uniform4fv(emisionLoc, emission.array)
        gl.uniform1f(shininessLoc, shininess)
    }
}