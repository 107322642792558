
import com.tanelso2.glmatrix.Mat4
import com.tanelso2.glmatrix.Vec4
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import org.khronos.webgl.WebGLRenderingContext as GL

class Application(private val gl: GL, private val resources: Resources) {

    private val camera = Camera()

    private val skeletonSelector = SourceSelector(
        resources, "skeleton",
        "skeletons/",
        "wasp.skel",
        ::setSkeleton
    )
    private val skinSelector = SourceSelector(
        resources,
        "skin",
        "skins/",
        "wasp.skin",
        ::setSkin
    )
    private val animationSelector = SourceSelector(
        resources,
        "animation",
        "animations/",
        "wasp_walk.anim",
        ::setAnimation
    )

    private lateinit var skeleton: Skeleton
    private lateinit var skin: Skin
    private lateinit var animationPlayer: AnimationPlayer

    private val material = Material(gl)
    private val lights = Lights(gl)

    private lateinit var lightingShadingProgram: ShaderProgram

    private var enableLighting = true
    private var fixedLights = false
    private var glCullFace = true

    init{

        // Set up the camera
        camera.aspect = gl.canvas.width.toFloat() / gl.canvas.height.toFloat()

        // Set up event listeners for the camera
        gl.canvas.addEventListener("wheel", camera::wheelEventListener)
        gl.canvas.addEventListener("keydown", camera::keyDownListener)
        gl.canvas.addEventListener("keyup", camera::keyUpListener)
        gl.canvas.addEventListener("mousemove", camera::mouseMoveListener)
        gl.canvas.addEventListener("mousedown", camera::mouseDownListener)

        gl.canvas.addEventListener("keydown", {
            it as KeyboardEvent
            when (it.key) {
                "l" -> {
                    enableLighting = !enableLighting

                    val enableLightingLoc = gl.getUniformLocation(lightingShadingProgram.program, "enablelighting")
                    gl.uniform1i(enableLightingLoc, enableLighting.compareTo(false))
                }
                "f" -> {
                    fixedLights = !fixedLights

                    val fixedLightsLoc = gl.getUniformLocation(lightingShadingProgram.program, "fixedLights")
                    gl.uniform1i(fixedLightsLoc, fixedLights.compareTo(false))
                }
                "c" -> {
                    glCullFace = !glCullFace

                    if (glCullFace)
                        gl.enable(GL.CULL_FACE)
                    else
                        gl.disable(GL.CULL_FACE)
                }
                " " -> animationPlayer.isPlaying = !animationPlayer.isPlaying
                "0" -> animationPlayer.animationTimeSeconds = 0.0
            }
        })

        // Disable annoying default behavior
        gl.canvas.onwheel = {event: Event -> event.preventDefault()}
        gl.canvas.onkeydown = {event: Event -> event.preventDefault()}

        // Add lights
        addLights()
    }

    private fun addLights() {
        lights.add(Light(
            position = Vec4(-1, 3, -10, 1.0),
            color = Vec4(1.0, 0.8, 0.6, 1.0)
        ))
        lights.add(Light(
            position = Vec4(-9, 7, 6, 1.0),
            color = Vec4(0.6, 1.0, 0.8, 1.0)
        ))
        lights.add(Light(
            position = Vec4(9.3, 6, 5, 1.0),
            color = Vec4(0.8, 0.6, 1.0, 1.0)
        ))
    }

    /**
     * Initialize shader program
     */
    fun initializePrograms() {
        lightingShadingProgram = ShaderProgram(gl, resources, "shaders/lighting.vert", "shaders/lighting.frag")

        // Use shader program
        lightingShadingProgram.useProgram()

        // Default gl and shader things
        val enableLightingLoc = gl.getUniformLocation(lightingShadingProgram.program, "enablelighting")
        gl.uniform1i(enableLightingLoc, enableLighting.compareTo(false))
        gl.enable(GL.CULL_FACE)
    }
    fun initializeObjects() {
        skeletonSelector.init()
        skinSelector.init()
        animationSelector.init()
    }

    /**
     * Deletes shader program. I'm not sure when or how to call this, but I made it anyways
     */
    fun cleanup() {
        gl.deleteProgram(lightingShadingProgram.program)
        skeleton.clean()
        skin.clean()
    }

    /**
     * Update object (moving, etc.)
     */
    fun update(currentTime: Double) {
        camera.update()

        skeleton.setPose(animationPlayer.update(currentTime))

        skeleton.update()
        skin.update(skeleton)
    }

    /**
     * Draws to the canvas
     */
    fun draw() {
        // Clear canvas
        gl.clear(GL.COLOR_BUFFER_BIT or GL.DEPTH_BUFFER_BIT)

        val transform = Mat4()
        transform.scale(0.5)

        lights.setUniforms(lightingShadingProgram)
        material.setUniforms(lightingShadingProgram)

        // Draw objects
        if (skin.isEmpty()) {
            skeleton.draw(camera.projectionMatrix, camera.viewMatrix, lightingShadingProgram)
        }
        skin.draw(camera.projectionMatrix, camera.viewMatrix, lightingShadingProgram)
    }

    /**
     * Helper to reset the camera
     */
    fun resetCamera() {
        camera.reset()
    }

    private fun setSkeleton(source: String) {
        if (::skeleton.isInitialized)
            skeleton.clean()
        skeleton = Skeleton.fromSource(gl, source)
    }

    private fun setSkin(source: String) {
        if (::skin.isInitialized)
            skin.clean()
        skin = Skin.fromSource(gl, source)
    }

    private fun setAnimation(source: String) {
        animationPlayer = AnimationPlayer(
            AnimationClip.fromSource(source),
            gl = gl,
            cycleInRange = true
        )
    }
}