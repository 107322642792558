import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.html.InputType
import kotlinx.html.dom.append
import kotlinx.html.js.div
import kotlinx.html.js.input
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import kotlin.math.PI

private val userAgentIsChrome = window.navigator.userAgent.contains("Chrome")

class DofSlider(
    val id: String,
    val setFunc: ((Number) -> Unit)? = {},
    val getFunc: (()->Number?)? = null,
    val sliderMin: Number = (-2* PI),
    val sliderMax: Number = (2* PI),
    val initialValue: Number = 0,
    val stepSize: Number = 0.01,
    val isDisabled: Boolean? = null
) {

    lateinit var sliderContainer: HTMLDivElement
    lateinit var slider: HTMLInputElement

    lateinit var valueDisplay: HTMLDivElement

    companion object {
        val dofs = mutableSetOf<DofSlider>()
        val container = document.getElementById("dof-adjustment-container") as HTMLDivElement

        /**
         * Removes all DOFs from the slider container.
         */
        fun clearAll() {
            for (dof in dofs) {
                dof.remove()
            }

            dofs.clear()
        }
    }

    init {
        addSlider(container, id, getFunc)
        dofs.add(this)
    }

    private fun addSlider(container: HTMLDivElement, id: String, getFunc: (()->Number?)? ) {
        container.append {
            sliderContainer = div("dof-slider-container") {
                div("dof-name") { +id }
                slider = input(InputType.range, classes = "dof-slider") {
                    min = sliderMin.toString()
                    max = sliderMax.toString()
                    step = stepSize.toString()
                    value = initialValue.toString()

                    if (isDisabled != null)
                        disabled = isDisabled
                    else if (min >= max)
                        disabled = true
                }
                valueDisplay = div("dof-value") {
                    +getUpdatedText(getFunc)
                }

                slider.addEventListener(
                    "input",
                    {
                        setFunc?.let { it1 -> it1(slider.valueAsNumber) }
                        valueDisplay.textContent =
                            getUpdatedText(getFunc)
                    }
                )
            }
        }
    }

    private fun getUpdatedText(getFunc: (() -> Number?)? = this.getFunc): String {
        return if ((getFunc == null)) {
            "???"
        } else if (getFunc() == null) {
            "???"
        } else {
            getFunc().toString()
        }
    }

    /**
     * Updates the slider with the value from getFunc.
     */
    fun updateValue(getFunc: (() -> Number?)? = this.getFunc) {
        // Freezes firefox, but works in Edge (and probably other Cromium)
        if (userAgentIsChrome) {
            if (getFunc === null)
                    return

            val newValue = getFunc()

            if (newValue !== null) {
                slider.value = newValue.toString()
            }

            valueDisplay.textContent = getUpdatedText(getFunc)
        }
    }

    fun remove() {
        sliderContainer.remove()
    }
}