
import org.khronos.webgl.WebGLProgram
import org.khronos.webgl.WebGLRenderingContext as GL

class ShaderProgram(private val gl: GL, vertexShader: VertexShader, fragmentShader: FragmentShader) {
    constructor(gl: GL, vertexShaderSource: String, fragmentShaderSource: String) :
            this(gl, VertexShader(gl, vertexShaderSource), FragmentShader(gl, fragmentShaderSource))
    constructor(gl: GL, resources: Resources, vertexShaderKey: String, fragmentShaderKey: String):
            this(gl, resources[vertexShaderKey]!!, resources[fragmentShaderKey]!!)

    val program: WebGLProgram = gl.createProgram()!!

    init{
        gl.attachShader(program, vertexShader.shader)
        gl.attachShader(program, fragmentShader.shader)
        gl.linkProgram(program)
    }

    fun useProgram() = gl.useProgram(program)
}
