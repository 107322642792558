/**
 * Represents a pose at a single point of time.
 *
 * The first 3 channels should be the x y z of the root translation.
 * Then each group of 3 channels is the x y z rotations of each joint in
 * depth-first order (order given in .skel file).
 */
class Pose(private val channelValues: List<Float>) {
    constructor(jointList: List<Skeleton.Joint>):
            this(jointListToChannelList(jointList))
    constructor(): this(listOf<Float>())

    companion object {
        private fun jointListToChannelList(jointList: List<Skeleton.Joint>): List<Float> {
            val list = mutableListOf<Float>()

            if (!jointList.isEmpty()) {
                // Add root translations
                list.addAll(
                    listOf<Float>(
                        jointList[0].offset.first.toFloat(),
                        jointList[0].offset.second.toFloat(),
                        jointList[0].offset.third.toFloat(),
                    )
                )

                // Add rotation channels
                for (joint in jointList) {
                    list.addAll(
                        listOf<Float>(
                            joint.pose.first.toFloat(),
                            joint.pose.second.toFloat(),
                            joint.pose.third.toFloat(),
                        )
                    )
                }
            }

            return list
        }
    }

    fun size() = channelValues.size
    operator fun get(i: Int) = getChannel(i)
    fun getChannel(i: Int) = channelValues[i]

    fun getRoot(): Triple<Float, Float, Float> {
        return Triple(this[0], this[1], this[2])
    }

    fun getJoint(jointNumber: Int): Triple<Float, Float, Float> {
        val i = (jointNumber+1) * 3
        return Triple(this[i], this[i+1], this[i+2])
    }

    fun toJointRotations(): List<Triple<Float, Float, Float>> {
        val numJoints = channelValues.size / 3 - 1

        val jointsPoseList = mutableListOf<Triple<Float, Float, Float>>()

        for (i in 0 until numJoints) {
            jointsPoseList.add(this.getJoint(i))
        }

        return jointsPoseList
    }
}