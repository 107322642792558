
import com.tanelso2.glmatrix.Vec4
import org.khronos.webgl.WebGLRenderingContext as GL

class Lights(val gl: GL) {
    val lights = mutableListOf<Light>()

    fun add(light: Light) = lights.add(light)

    fun setUniforms(shaderProgram: ShaderProgram) {
        val program = shaderProgram.program

        val lightpositions = mutableListOf<Float>()
        val lightcolors = mutableListOf<Float>()

        for (light in lights) {
            lightpositions.add(light.position[0])
            lightpositions.add(light.position[1])
            lightpositions.add(light.position[2])
            lightpositions.add(light.position[3])
            lightcolors.add(light.color[0])
            lightcolors.add(light.color[1])
            lightcolors.add(light.color[2])
            lightcolors.add(light.color[3])
        }

        // Get uniform locations
        val nlights_loc = gl.getUniformLocation(program, "nlights")
        val lightpositions_loc = gl.getUniformLocation(program, "lightpositions")
        val lightcolors_loc = gl.getUniformLocation(program, "lightcolors")

        // Set uniforms
        gl.uniform1i(nlights_loc, lights.size)
        gl.uniform4fv(lightpositions_loc, lightpositions.toTypedArray())
        gl.uniform4fv(lightcolors_loc, lightcolors.toTypedArray())
    }
}

class Light(
    val position: Vec4,
    val color: Vec4 = Vec4(1.0, 1.0, 1.0, 1.0),
)