
import com.tanelso2.glmatrix.Mat4
import com.tanelso2.glmatrix.Vec3
import com.tanelso2.glmatrix.Vec4
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.events.MouseEvent
import org.w3c.dom.events.WheelEvent
import kotlin.math.PI

class Camera {

    private var fovy = 45f
    var aspect = 640f/480f
    private var nearClip = 0.1f
    private var farClip = 100f

    private var distance = 10f
    private var azimuth = 0f
    private var incline = 20f

    private var center: Vec3 = Vec3(0, 0, 0)

    lateinit var projectionMatrix: Mat4
    lateinit var viewMatrix: Mat4

    private var moving = false

    init {
        update()
    }

    fun update() {

        limitIncline()

        // Compute camera world matrix
        var world = Mat4()
        world.translate(center)
        world.translate(Vec3(0f, 0f, distance))

        var eulerX = Mat4()
        eulerX.rotateX(toRadians(-incline))
        var eulerY = Mat4()
        eulerY.rotateY(toRadians(-azimuth))

        world = eulerY * eulerX * world

        // Compute view matrix (inverse of world matrix)
        val view = world.clone()
        view.invert()

        // Compute perspective projection matrix
        val project = Mat4()
        project.perspective(toRadians(fovy), aspect, nearClip, farClip)

        // Compute final view-projection matrix
        projectionMatrix = project
        viewMatrix = view
    }

    /**
     * Constrain the incline to +-90 degrees
     */
    private fun limitIncline() {
        incline = minOf(incline, 95f)
        incline = maxOf(incline, -95f)
    }

    fun reset() {
        fovy = 45f
        aspect = 640f/480f
        nearClip = 0.1f
        farClip = 100f

        distance = 10f
        azimuth = 0f
        incline = 20f

        center = Vec3(0,0,0)
    }

    /**
     * Scrolls with the mouse wheel
     */
    fun wheelEventListener(event: Event){
        val wheelEvent = event as WheelEvent
        val wheelDelta = wheelEvent.deltaY

        val scrollMultiplier = 1.07f
        if (wheelDelta < 0) {
            distance *= (1/scrollMultiplier)
        }
        else if (wheelDelta > 0) {
            distance *= scrollMultiplier
        }
    }

    fun keyDownListener(event: Event){
        val keyEvent = event as KeyboardEvent

        if (keyEvent.key == "Shift") {
            moving = true
        }
        when (keyEvent.key) {
            "ArrowUp" -> {
                incline += 5f
            }
            "ArrowDown" -> {
                incline -= 5f
            }
            "ArrowLeft" -> {
                azimuth += 5f
            }
            "ArrowRight" -> {
                azimuth -= 5f
            }
            "r" -> {
                reset()
            }
        }
    }

    fun keyUpListener(event: Event) {
        event as KeyboardEvent

        if (event.key == "Shift")
            moving = false
    }

    var oldMouseX: Double = 0.0;
    var oldMouseY: Double = 0.0;

    fun mouseMoveListener(event: Event) {
        val mouseEvent = event as MouseEvent

        if (mouseEvent.buttons.toInt() != 0) {

            val diffX = mouseEvent.offsetX - oldMouseX
            val diffY = mouseEvent.offsetY - oldMouseY

            if  (!moving) {

                // Move the camera
                azimuth += diffX.toFloat()
                incline += diffY.toFloat()
            }
            else {
                // translate camera
                center += Vec3(Vec4(-diffX, diffY, 0, 0)) * distance * 0.001
            }
        }

        oldMouseX = mouseEvent.offsetX
        oldMouseY = mouseEvent.offsetY
    }

    fun mouseDownListener(event: Event) {
        val mouseEvent = event as MouseEvent

        oldMouseX = mouseEvent.offsetX
        oldMouseY = mouseEvent.offsetY
    }
}

fun toRadians(degrees: Float) : Float {
    return (degrees * PI / 180f).toFloat()
}