import org.khronos.webgl.WebGLShader
import org.khronos.webgl.WebGLRenderingContext as GL

open class Shader(val gl: GL, val shaderSource: String, val shaderType: Int) {
    val shader: WebGLShader

    init {
        shader = gl.createShader(shaderType)!!
        gl.shaderSource(shader, shaderSource)
        gl.compileShader(shader)

        // Check that the shaders compiled correctly
        require(gl.getShaderParameter(shader, GL.COMPILE_STATUS) as Boolean) {
            gl.getShaderInfoLog(shader)!!
            gl.deleteShader(shader)
        }
    }
}

class VertexShader(gl: GL, shaderSource: String): Shader(gl, shaderSource, GL.VERTEX_SHADER)
class FragmentShader(gl: GL, shaderSource: String): Shader(gl, shaderSource, GL.FRAGMENT_SHADER)