
import org.khronos.webgl.WebGLRenderingContext as GL

class AnimationPlayer(
    val clip: AnimationClip,
    animationTimeSeconds: Double = 0.0,
    var isPlaying: Boolean = false,
    val gl: GL? = null,
    var cycleInRange: Boolean = false,
) {
    var animationTimeSeconds: Double = animationTimeSeconds
        set(value) {
            field = value
            skeletonNeedsUpdating = true
        }

    var skeletonNeedsUpdating: Boolean = false

    private var lastTimeSeconds: Double = 0.0

    fun update(currentTimeMilliseconds: Double): Pose {
        advanceTime(currentTimeMilliseconds)

        return if (skeletonNeedsUpdating) {
            skeletonNeedsUpdating = false
            clip[animationTimeSeconds.toFloat()]
        }
        else
            Pose()
    }

    private fun advanceTime(currentTimeMilliseconds: Double) {
        val currentTimeSeconds = currentTimeMilliseconds * 0.001

        if (isPlaying) {
            val timeDiffSeconds = currentTimeSeconds - lastTimeSeconds
            animationTimeSeconds += timeDiffSeconds

            val timeStart = clip.start
            val timeEnd = clip.end

            animationTimeSeconds = timeStart + (animationTimeSeconds - timeStart).mod(timeEnd - timeStart)
        }

        lastTimeSeconds = currentTimeSeconds
    }

}