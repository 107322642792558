
import kotlinx.browser.window
import org.w3c.fetch.*

private const val NUM_RETRIES = 2

/**
 * Loads resources
 *
 * Based on https://github.com/tanelso2/kotlin-webgl-test/blob/master/src/ResourceLoader.kt
 */
class Resources(vararg val resourceURIs: String, var callAfter: () -> Unit = {}) {
    private class ResourceInfo {
        var loaded = false
        var value: String? = null
    }
    private val resourceMap: MutableMap<String, ResourceInfo> = HashMap()
    private var fetchParams = RequestInit(
        method = "GET",
        cache = RequestCache.NO_STORE,
        mode = RequestMode.SAME_ORIGIN,
    )

    init {
        for (uri in resourceURIs) {
            resourceMap[uri] = ResourceInfo()
            makeRequest(uri, NUM_RETRIES)
        }
    }

    private fun makeRequest(uri: String, depth: Int = 0){

        /**
         * Handles HTTP errors if they happen
         */
        fun handleHTTPErrors(response: Response) {
            if (!response.ok) {
                if (depth > 0) {
                    console.warn("Failed to fetch \"$uri\" with ${response.status}: ${response.statusText}; Retries left: $depth")

                    makeRequest(uri, depth - 1)
                } else {
                    console.error("Failed to fetch \"$uri\" with ${response.status}: ${response.statusText}. No retries left; program may not work as intended.")
                }
            }
        }

        window.fetch(uri, fetchParams)
            .then { response ->
                handleHTTPErrors(response)

                response
            }
            .then { response -> response.text()
            }
            .then { text ->
                resourceMap[uri]?.loaded = true
                resourceMap[uri]?.value = text

                if (isLoaded()) {
                    callAfter()
                }

            }
    }

    private fun isLoaded(): Boolean {
        return resourceMap.all { it.value.loaded }
    }

    operator fun get(uri: String): String? {
        return resourceMap[uri]?.value
    }
}