
import com.tanelso2.glmatrix.Mat4
import com.tanelso2.glmatrix.Vec3
import kotlinx.browser.document
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import kotlin.math.PI
import kotlin.math.max
import kotlin.math.min
import org.khronos.webgl.WebGLRenderingContext as GL

class Skeleton private constructor(val gl: GL) {

    var rootJoint: Joint? = null

    val jointList = mutableListOf<Joint>()

    var constraints: Boolean = true

    var hasUpdated = true

    class Joint(private val skeleton: Skeleton, private val name: String) {
        // Joint configuration
        var offset: Triple<Number, Number, Number> = Triple(0, 0, 0)
            set(value) {
                skeleton.hasUpdated = true
                field = value

                dofSliders.forEach { dofSlider -> dofSlider.updateValue() }
            }

        var boxmin: Triple<Number, Number, Number> = Triple(-0.1, -0.1, -0.1)
        var boxmax: Triple<Number, Number, Number> = Triple(0.1, 0.1, 0.1)

        var pose: Triple<Number, Number, Number> = Triple(0, 0, 0)
            set(value) {
                skeleton.hasUpdated = true
                field = value

                dofSliders.forEach { dofSlider -> dofSlider.updateValue() }
            }

        var rotxlimit: Pair<Number, Number> = Pair(-Float.MAX_VALUE, Float.MAX_VALUE)
        var rotylimit: Pair<Number, Number> = Pair(-Float.MAX_VALUE, Float.MAX_VALUE)
        var rotzlimit: Pair<Number, Number> = Pair(-Float.MAX_VALUE, Float.MAX_VALUE)

        // For rendering
        lateinit var cube: Cube

        // Model Matrices
        lateinit var localModel: Mat4
        lateinit var worldModel: Mat4

        // Tree structure
        var childJoints = mutableSetOf<Joint>()
        var parentJoint: Joint? = null  // null means is root

        // DOF sliders
        val dofSliders = mutableSetOf<DofSlider>()

        /**
         * Initialize things. All values should be set.
         */
        fun initialize(gl: GL) {
            cube = Cube(gl, boxmin, boxmax)

            initDofSliders()

            for (joint in childJoints) {
                joint.initialize(gl)
            }
        }

        fun clean(){
            if (::cube.isInitialized)
                cube.clean()
            for (joint in childJoints) {
                joint.clean()
            }

            for (dofSlider in dofSliders) {
                dofSlider.remove()
            }
        }

        private fun initDofSliders() {
            // If root, add sliders for offset
                dofSliders.apply {

                    if (parentJoint === null) {
                        add(DofSlider(
                            id = "${name}-offset-x",
                            sliderMin = -5,
                            sliderMax = 5,
                            initialValue = 0,
                            setFunc = { x -> offset = Triple(x, offset.second, offset.third) },
                            getFunc = { offset.first }
                        ))

                        add(DofSlider(
                            id = "${name}-offset-y",
                            sliderMin = -5,
                            sliderMax = 5,
                            initialValue = 0,
                            setFunc = { y -> offset = Triple(offset.first, y, offset.third) },
                            getFunc = { offset.second }
                        ))

                        add(DofSlider(
                            id = "${name}-offset-z",
                            sliderMin = -5,
                            sliderMax = 5,
                            initialValue = 0,
                            setFunc = { z -> offset = Triple(offset.first, offset.second, z) },
                            getFunc = { offset.third }
                        ))
                    }

                    add(DofSlider(
                        "${name}-x",
                        sliderMin = max(rotxlimit.first.toDouble(), -2* PI),
                        sliderMax = min(rotxlimit.second.toDouble(), 2*PI),
                        initialValue = pose.first,
                        setFunc = {x -> pose = Triple(x, pose.second, pose.third) },
                        getFunc = { pose.first }
                    ))

                    add(DofSlider(
                        "${name}-y",
                        sliderMin = max(rotylimit.first.toDouble(), -2* PI),
                        sliderMax = min(rotylimit.second.toDouble(), 2*PI),
                        initialValue = pose.second,
                        setFunc = {y -> pose = Triple(pose.first, y, pose.third) },
                        getFunc = { pose.second }
                    ))

                    add(DofSlider(
                        "${name}-z",
                        sliderMin = max(rotzlimit.first.toDouble(), -2* PI),
                        sliderMax = min(rotzlimit.second.toDouble(), 2*PI),
                        initialValue = pose.third,
                        setFunc = {z -> pose = Triple(pose.first, pose.second, z) },
                        getFunc = { pose.third }
                    ))
                }

        }

        /**
         * Creates transform matrices from the offset and pose.
         */
        fun computeModelMatrices(constraints: Boolean, parentWorldModel: Mat4 = Mat4()) {
            var x = pose.first
            var y = pose.second
            var z = pose.third

            if (constraints) {
                x = minOf(maxOf(x.toFloat(), rotxlimit.first.toFloat()), rotxlimit.second.toFloat())
                y = minOf(maxOf(y.toFloat(), rotylimit.first.toFloat()), rotylimit.second.toFloat())
                z = minOf(maxOf(z.toFloat(), rotzlimit.first.toFloat()), rotzlimit.second.toFloat())
            }

            localModel = Mat4()

            // Offset
            val offsetTransform = Mat4()
            offsetTransform.translate(Vec3(offset.first, offset.second, offset.third))

            // Pose
            val eulerX = Mat4()
            eulerX.rotateX(x)
            val eulerY = Mat4()
            eulerY.rotateY(y)
            val eulerZ = Mat4()
            eulerZ.rotateZ(z)

            // Note: offset is applied after rotation because it's the offset
            // from the previous joint.
            localModel = offsetTransform * eulerZ * eulerY * eulerX

            worldModel = parentWorldModel * localModel

            for (joint in childJoints) {
                joint.computeModelMatrices(constraints, worldModel)
            }
        }

        /**
         * Draw each joint recursively. Calculate the transform matrix and draw the cube.
         */
        fun draw(projMat: Mat4, viewMat: Mat4, shaderProgram: ShaderProgram, depth: Int = 0){

            cube.draw(projMat, viewMat, shaderProgram, worldModel)

//            console.warn("Drawing joint - Depth: $depth")

            childJoints.forEach { it.draw(projMat, viewMat, shaderProgram, depth+1) }
        }
    }

    /**
     * Creates transformation matrices
     */
    fun update(){
        rootJoint?.computeModelMatrices(constraints)
    }

    fun draw(projMat: Mat4, viewMat: Mat4, shaderProgram: ShaderProgram){
        rootJoint?.draw(projMat, viewMat, shaderProgram)
    }

    fun getPose(): Pose = Pose(jointList)

    fun setPose(pose: Pose) {
        if (pose.size() >= 3)
            rootJoint?.offset = Triple(pose[0], pose[1], pose[2])

        for ((joint, jointPose) in (jointList zip pose.toJointRotations())) {
            joint.pose = jointPose
        }
    }

    fun clean() {
        rootJoint?.clean()
    }

    companion object {

        fun fromSource(gl: GL, source: String): Skeleton {
            val skeleton = Skeleton(gl)
            val skeletonLoader = SkeletonLoader(skeleton)
            skeletonLoader.loadSource(source)

            // Initialize cube stuff
            skeleton.rootJoint?.initialize(gl)

            // initialize matrices
            skeleton.rootJoint?.computeModelMatrices(true)

            // Constraint check box. Add event listener and initialize to true
            val checkbox = document.getElementById("constrain-checkbox")
            checkbox?.addEventListener("input", skeleton::constraintCheckBoxEventListener)
            checkbox?.let { (it as HTMLInputElement).checked = true }

            return skeleton
        }
    }

    fun constraintCheckBoxEventListener(@Suppress("UNUSED_PARAMETER") event: Event) {
        val box = document.getElementById("constrain-checkbox") as HTMLInputElement
        console.error("Changing constraints to: ${box.checked}")

        constraints = box.checked
    }

}


/**
 * Contains things for loading skeleton that does not go in the skeleton
 */
private class SkeletonLoader(val skeleton: Skeleton) {

    var currentJoint: Skeleton.Joint? = skeleton.rootJoint

    companion object {
        val xyzKeywords = arrayOf(
            "offset",
            "boxmin",
            "boxmax",
            "pose",
        )

        val minMaxKeywords = arrayOf(
            "rotxlimit",
            "rotylimit",
            "rotzlimit",
        )

        val jointKeywords = arrayOf("balljoint")

        val keywords = arrayOf(
            *xyzKeywords,
            *minMaxKeywords,
            *jointKeywords,
        )
    }

    fun loadSource(source: String) {parseSource(source)}

    private fun parseSource(source: String) {
        val lines = source.split('\n')

        for ((lineNumber, line) in lines.withIndex()) {
            processLine(lineNumber, line)
        }
    }

    private fun processLine(lineNumber: Number, line: String) {
        val tokens = tokenizeLine(line)

        if (tokens.isEmpty()) {
            return
        }

        val keyword = tokens[0]

        if (keyword in xyzKeywords) {
            // Expects 4 tokens (keyword + 3 args)
            if (tokens.size != 4) {
                console.warn("$lineNumber: Error: keyword $keyword expect 3 arguments")
                return
            }
            else if (currentJoint === null) {
                console.warn("$lineNumber: Error: $keyword must be used on a joint")
                return
            }

            // Get arguments
            val x = tokens[1].toFloatOrNull()
            val y = tokens[2].toFloatOrNull()
            val z = tokens[3].toFloatOrNull()

            // Check is number
            if ((x === null) or (y === null) or (z === null)) {
                console.warn("$lineNumber: Error: for keyword $keyword, x, y, and z must be numbers. got $x, $y, $z instead")
                return
            }
            else {
                // Set the properties
                when (keyword) {
                    "offset" -> {
                        currentJoint!!.offset = Triple(x!!, y!!, z!!)
                    }
                    "boxmin" -> {
                        currentJoint!!.boxmin = Triple(x!!, y!!, z!!)
                    }
                    "boxmax" -> {
                        currentJoint!!.boxmax = Triple(x!!, y!!, z!!)
                    }
                    "pose" -> {
                        currentJoint!!.pose = Triple(x!!, y!!, z!!)
                    }
                }
            }
        }
        else if (keyword in minMaxKeywords) {
            // Expects 3 tokens (keyword + 2 args)
            if (tokens.size != 3) {
                console.warn("$lineNumber: Error: keyword $keyword expect 2 arguments")
                return
            }
            else if (currentJoint === null) {
                console.warn("$lineNumber: Error: $keyword must be used on a joint")
                return
            }

            // Get arguments
            val min = tokens[1].toFloatOrNull()
            val max = tokens[2].toFloatOrNull()

            if ((min === null) or (max === null)) {
                console.warn("$lineNumber: Error: for keyword $keyword, min and max must be numbers. got $min and $max instead")
                return
            }

            // Set properties
            when (keyword) {
                "rotxlimit" -> {
                    currentJoint!!.rotxlimit = Pair(min!!, max!!)
                }
                "rotylimit" -> {
                    currentJoint!!.rotylimit = Pair(min!!, max!!)
                }
                "rotzlimit" -> {
                    currentJoint!!.rotzlimit = Pair(min!!, max!!)
                }
            }
        }
        else if (keyword in jointKeywords) {
            // Expects 3 tokens (keyword + 1 args) plus {
            if (tokens.size < 2) {
                print("$lineNumber: Error: for keyword $keyword, more tokens are expected, got $tokens")
                return
            }
            var name = ""

            // Set joint name
            if (tokens.size == 2) {
                if (tokens[1] != "{") {
                    console.warn("$lineNumber: Error: Expecting {, got ${tokens[1]} instead in $tokens")
                    return
                }

                // No joint name; make one up
                name = "joint"
            }
            else if (tokens.size == 3) {
                if (tokens[2] != "{") {
                    console.warn("$lineNumber: Error: Expecting {, got ${tokens[2]} instead in $tokens")
                    return
                }

                name = tokens[1]
            }

            // Create and add joint
            val newJoint = Skeleton.Joint(skeleton, name)

            skeleton.jointList.add(newJoint)

            if (currentJoint == null) {
                skeleton.rootJoint = newJoint
                currentJoint = skeleton.rootJoint
            }
            else {
                currentJoint!!.childJoints.add(newJoint)
                newJoint.parentJoint = currentJoint
                currentJoint = newJoint
            }
        }
        else if (keyword.contains('}') or (keyword == "}")) {
            // Go up to parent joint
            currentJoint = currentJoint!!.parentJoint
        } else {
            // Unknown keyword?
            console.warn("$lineNumber: Error: Unknown keyword '$keyword'; ${keyword == "}"}; ${keyword.length}")
        }
    }

    /**
     * Splits a line into tokens. Assumes tokens are separated by whitespace.
     */
    private fun tokenizeLine(line: String): List<String> {
        return line.split(" ", "\t", "\n").filter { it.isNotEmpty() }
    }


}